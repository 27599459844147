import React, { Component } from "react"

import loginSession from '../../../lib/class/loginSession'

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import styles from '../../styles/pages/api.module.css'

import btnclose from '../../images/button/close.png'


const contentlist = [
			{
				name: "Courier",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/courier.json"
			},
			{
				name: "Product",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/product.json"
			},
			{
				name: "Locations",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/location.json"
			},
			{
				name: "Cart",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/cart.json"
			},
			{
				name: "Address",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/address.json"
			},
			{
				name: "Contact",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/contact.json"
			},
			{
				name: "Profile",
				url: "https://api.iamnet.ph/document/swagger/negosyonow/profile.json"
			}
	];

export default class NegosyoNowApiIndex extends Component {
	state = {
		apititle: "",
		apiurl: "",
		loginObj: null
	}

	componentDidMount() {
		this.setState({
			loginObj: new loginSession()
		});
	}


	closeSwaggerUI() {
		this.setState({
			apititle: "",
			apiurl: "",
		});
	}

	loadSwaggerUI(title, jsonurl) {
		this.state.loginObj.getToken()
		.then((tokenresponse) => {
			this.setState({
				apititle: title+" APIs",
				apiurl: "https://iam-swaggerui.pages.dev/index.html?token="+tokenresponse+"&src="+jsonurl,

			});
			//window.open("https://democontent.iamnet.ph/util/swagger-ui/index.html?token="+tokenresponse+"&src="+jsonurl,"_blank");
		});
	}



	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='api' fullPath='api/negosyonow' pageTitle="NegosyoNow APIs" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						<ul>
						{
							contentlist.map((data,index) => {
									return <li key={"listitem"+index}><button className={"buttonlink"} onClick={()=>this.loadSwaggerUI(data.name, data.url)}>{data.name}</button></li>;
							})
						}
						</ul>
					</div>
					{ this.state.apiurl.length > 0 &&
						<div className={styles.apifullscreen}>
							<div className={styles.apiscreencontrol}>
								<span className={"font-size-medium"}>{this.state.apititle}</span>
								<button className={"buttonlink"} onClick={()=>this.closeSwaggerUI()}>
									<img src={btnclose} alt="Close" />
								</button>
							</div>
							<iframe title="swaggerui" className={styles.apiscreencontent} src={this.state.apiurl} />
						</div>
					}
				</GatedContent>
			</Layout>
		)
	}
}



